<template>
  <div class="email">
    <div class="wrapper">
      <div class="title font-md">
        <span>이메일 인증</span>
      </div>
      <div class="desc font-sm">회원 통합에 따라 기존 회원님의 개인정보 보호를 위해 이메일 인증이 필요합니다.</div>
      <div class="form">
        <input type="text" class="font-sm border-focus-point form-control" maxlength="6" placeholder="인증 코드를 입력해주세요." ref="codeInputRef" @keyup.enter="submit()" v-show="state.sent"/>
        <input type="email" class="font-sm border-focus-point form-control" placeholder="이메일 주소를 입력해주세요." ref="emailInputRef" @keyup.enter="sendCode()" v-show="!state.sent"/>
      </div>
    </div>
    <div class="actions">
      <div class="row">
        <template v-if="state.sent">
          <div class="col left">
            <button class="btn btn-point btn-block font-sm" @click="submit()" :disabled="state.timeout.status === 'expired'">
              <span>{{ state.timeout.status === "expired" ? "유효 시간 만료" : "인증 확인" }}</span>
              <span class="font-xs" v-if="state.timeout.status === 'ticking'"> ({{ state.timeout.minutes }}:{{ state.timeout.seconds }})</span>
            </button>
          </div>
          <div class="col right">
            <button class="btn btn-secondary btn-block font-sm" @click="reset()">다시 입력</button>
          </div>
        </template>
        <div class="col" v-else>
          <button class="btn btn-point btn-block font-sm" @click="sendCode()">인증 메일 발송</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, nextTick, onMounted, onUnmounted, reactive, ref} from "@vue/composition-api";
import http from "@/scripts/http";
import lib from "@/scripts/lib";
import store from "@/scripts/store";
import mixin from "@/scripts/mixin";
import Phone from "@/components/Phone";
import {httpError} from "@/scripts/httpError";

function Component(initialize) {
  this.name = "modalEmail";
  this.initialize = initialize;
}

export default defineComponent({
  components: {Phone},
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      store.commit("tuneModal", {component, size: "sm"});
    });

    const state = reactive({
      sent: false,
      timeout: {
        timer: 0,
        status: "",
        minutes: "",
        seconds: "",
      }
    });

    const emailInputRef = ref();
    const codeInputRef = ref();
    const modalParams = store.getters.modalParams(component);
    let email, insertMailSeq;

    const reset = () => {
      state.sent = false;
      clearInterval(state.timeout.timer);

      nextTick(() => {
        emailInputRef.value.value = "";
        emailInputRef.value.focus();
      });
    };

    const sendCode = () => {
      email = emailInputRef.value.value;

      if (!email) {
        store.commit("setSwingMessage", "이메일 주소를 입력해주세요.");
        emailInputRef.value.focus();
        return;
      } else if (!lib.isValidEmail(email)) {
        store.commit("setSwingMessage", "이메일 주소가 올바른지 확인해주세요.");
        emailInputRef.value.focus();
        return;
      }

      clearInterval(state.timeout.timer);

      http.post(`/api/member/${modalParams.memberSeq}/auth-mail`, {email}).then(({data}) => {
        state.sent = true;
        insertMailSeq = data.body.insertMailSeq;

        store.commit("setSwingMessage", "입력하신 주소로 이메일을 발송하였습니다. 이메일로 받으신 인증 코드 6자리를 입력해주세요.");

        state.timeout.status = "ticking";
        state.timeout.minutes = "20";
        state.timeout.seconds = "00";

        state.timeout.timer = setInterval(() => {
          let minutes = window.Number(state.timeout.minutes);
          let seconds = window.Number(state.timeout.seconds);

          if (seconds === 0) {
            state.timeout.seconds = "59";
            state.timeout.minutes = lib.getZeroAddedNum(--minutes);
          } else {
            state.timeout.seconds = lib.getZeroAddedNum(--seconds);

            if (seconds === 0 && minutes === 0) {
              state.timeout.status = "expired";
              store.commit("setSwingMessage", "유효 입력 시간이 만료되었습니다.");
              clearInterval(state.timeout.timer);
            }
          }
        }, 1000);

        nextTick(() => {
          codeInputRef.value.value = "";
          codeInputRef.value.focus();
        });
      }).catch(httpError((err) => {
        switch (err?.response?.status) {
          case 409:
            emailInputRef.value.focus();
            return;
        }
      }));
    };

    const submit = () => {
      const args = {
        authCode: codeInputRef.value.value,
        email,
        insertMailSeq
      };

      if (!args.authCode || args.authCode.length !== 6) {
        codeInputRef.value.focus();
        return store.commit("setSwingMessage", "인증 코드 6자리를 입력해주세요.");
      }

      http.put(`/api/member/${modalParams.memberSeq}/email-check`, args).then(({data}) => {
        store.commit("setSwingMessage", data.message);
        data.code === "200 OK" && store.commit("closeModal", {name: component.name});
      }).catch(httpError());
    };

    onMounted(() => {
      emailInputRef.value.focus();
    });

    onUnmounted(() => {
      clearInterval(state.timeout.timer);
    });

    return {component, state, codeInputRef, emailInputRef, reset, sendCode, submit};
  },
});
</script>

<style lang="scss" scoped>
.email {
  > .wrapper {
    background: #fff;
    padding: $px25;

    .title {
      margin-bottom: $px9;
    }

    .desc {
      margin-bottom: $px25;
      color: #666;
    }

    .form {
      input {
        display: block;
        margin-top: $px13;
        height: $formHeightLg;
        font-size: $px14;
        border-color: #ddd;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .actions {
    .row {
      > div {
        button {
          padding: $px15;
          height: $px56;
          border-radius: 0;
        }

        &.left {
          padding-right: 0;
        }

        &.right {
          padding-left: 0;
        }
      }
    }
  }
}
</style>